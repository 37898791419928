$on-mobile: 768px;
$on-tablet: 1024px;
$on-desktop: 1324px;
$on-large-desktop: 1440px;

body {
  // background: white url("../images/bg-white.jpg") repeat-y center -400px;
  font-size: 14px;
  color: #141414;
}

.container {
  max-width: 1600px;
  padding: 0 48px;
  margin: auto;
  @media screen and (max-width: $on-desktop) {
    max-width: 1440px;
    padding: 0px 8px;
  }
}

#bg-image {
  position: fixed;
  top: -200px;
  right: 0;
  width: 100%;
  height: auto;
  z-index: -10;
  @media screen and (max-width: $on-tablet) {
    top: 0;
    height: 100vw;
  }
}

header {
  margin-top: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: $on-mobile) {
    margin-top: 16px;
  }
  .header-left {
    .companyName {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: -0.03em;
      color: #6a6a6a;
      @media screen and (max-width: $on-mobile) {
        font-size: 12px;
      }
    }
    h1 {
      font-family: "Inter";
      font-weight: 500;
      font-size: 24px;
      letter-spacing: -0.03em;
      color: #141414;
      margin: 0;
      @media screen and (max-width: $on-mobile) {
        font-size: 16px;
      }
    }
  }

  .navbar {
    z-index: 5000;
    width: 418px;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    position: fixed;
    top: 32px;
    bottom: auto;
    left: 0%;
    right: 0%;
    opacity: 0;

    &.headerScrolled {
      opacity: 1;
      animation: getout 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      @media screen and (max-width: $on-tablet) {
        animation: none;
      }
    }

    @media screen and (max-width: $on-tablet) {
      bottom: 0;
      top: auto;
      width: 100%;
      animation: none;
      opacity: 1;
    }

    ul {
      display: flex;
      align-items: center;
      justify-content: center;
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        margin: 8px 4px;
        a {
          transition: background-color 0.2s ease;
          padding: 8px 12px;
          display: block;
          font-size: 16px;
          color: #6a6a6a;
          text-decoration: none;
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          cursor: pointer;
          border-radius: 5px;
          @media screen and (max-width: $on-tablet) {
            padding: 12px;
            font-size: 12px;
          }

          &.active {
            color: #141414;
            font-weight: 600;
            border-radius: 5px;
            &:hover {
              background-color: transparent;
            }
          }
          &:hover {
            background-color: rgba(0, 0, 0, 0.03);
          }
        }
      }
    }
    @keyframes getout {
      0% {
        opacity: 0;
        transform: translate(0px, -24px);
      }
      100% {
        opacity: 1;
        transform: translate(0px, 0px);
      }
    }
    @media screen and (max-width: $on-tablet) {
      @keyframes getout {
        0% {
          opacity: 0;
          transform: translate(0px, 24px);
        }
        100% {
          opacity: 1;
          transform: translate(0px, 0px);
        }
      }
    }
  }

  .header-right {
    a {
      display: flex;
      align-items: center;
      border: 1px solid rgba(0, 0, 0, 0.1);
      padding: 6px 12px;
      text-decoration: none;
      border-radius: 5px;
      background: #fff;
      cursor: pointer;
      @media screen and (max-width: $on-mobile) {
        padding: 6px 10px;
      }

      &:hover {
        background: rgba(0, 0, 0, 0.03);
      }

      svg {
        margin-right: 12px;
        @media screen and (max-width: $on-mobile) {
          margin-right: 10px;
        }
      }

      .workstatus {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        span {
          letter-spacing: -0.03em;
          color: #6a6a6a;
          font-size: 12px;
          font-weight: 500;
          display: block;
        }
        span.badge {
          display: block;
          font-size: 11px;
          margin-top: 3px;
          color: #1b1b1b;
        }
      }
    }
  }
}

.intro {
  margin-top: 120px;

  picture {
    img {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      margin-bottom: 24px;
      filter: drop-shadow(0px 12px 28px rgba(0, 0, 0, 0.1));
      @media screen and (max-width: $on-mobile) {
        width: 64px;
        height: 64px;
        margin-bottom: 8px;
      }
    }
  }
  .intro-text {
    max-width: 100%;
    margin-top: 64px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 64px;
    line-height: 72px;
    letter-spacing: -0.04em;
    color: #141414;

    @media screen and (max-width: $on-desktop) {
      font-size: 48px;
      line-height: 56px;
    }

    @media screen and (max-width: $on-tablet) {
      font-size: 18px;
      line-height: 26px;
      max-width: 80%;
      margin-top: 32px;
    }
    @media screen and (max-width: $on-mobile) {
      max-width: 100%;
    }
    div {
      display: inline-block;
    }
    .colored {
      color: #6a6a6a;
    }
  }
  .intro-meta {
    margin-top: 64px;
    display: flex;
    align-items: center;
    @media screen and (max-width: $on-mobile) {
      flex-direction: column;
      align-items: flex-start;
      margin-top: 24px;
    }
  }
  .intro-location {
    display: flex;
    align-items: center;
    margin-right: 40px;
    justify-content: flex-start;
    @media screen and (max-width: $on-mobile) {
      margin-bottom: 8px;
    }
    .intro-location-icon {
      width: 18px;
      height: 17px;
      margin-right: 16px;
      fill: #434343;
      @media screen and (max-width: $on-mobile) {
        margin-right: 12px;
        overflow: visible;
        width: 13px;
        height: 12px;
      }
    }
    .intro-location-text {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      /* identical to box height, or 133% */

      letter-spacing: -0.04em;

      color: #1b1b1b;
      @media screen and (max-width: $on-tablet) {
        font-size: 16px;
      }

      @media screen and (max-width: $on-mobile) {
        font-size: 14px;
      }
    }
  }
  .intro-mail {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .intro-mail-icon {
      width: 18px;
      height: 17px;
      margin-right: 16px;
      fill: #434343;
      @media screen and (max-width: $on-mobile) {
        margin-right: 12px;
        overflow: visible;
        width: 13px;
        height: 12px;
      }
    }
    .intro-mail-text {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      /* identical to box height, or 133% */

      letter-spacing: -0.04em;

      color: #1b1b1b;
      @media screen and (max-width: $on-tablet) {
        font-size: 16px;
      }
      @media screen and (max-width: $on-mobile) {
        font-size: 14px;
      }
    }
  }
}

.work {
  margin-top: 240px;
  @media screen and (max-width: $on-mobile) {
    margin-top: 180px;
  }
  .work-title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 56px;
    letter-spacing: -0.03em;
    color: #141414;
    @media screen and (max-width: $on-mobile) {
      font-size: 20px;
    }
  }
  ul.work-content {
    list-style: none;
    margin: 64px 0 0 0;
    padding: 0;
    @media screen and (max-width: $on-mobile) {
      margin: 32px 0 0 0;
    }
    li {
      margin: 220px 0 0 0;
      padding: 0;
      @media screen and (max-width: $on-mobile) {
        margin: 64px 0 0 0;
      }
      &:first-child {
        @media screen and (max-width: $on-mobile) {
          margin: 24px 0 0 0;
        }
      }
      .work-content-item-label {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.03em;
        color: #686868;
        @media screen and (max-width: $on-mobile) {
          font-size: 12px;
          line-height: 16px;
        }
      }
      .work-content-item-title {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 30px;
        line-height: 40px;
        margin-top: 16px;
        color: #141414;
        @media screen and (max-width: $on-mobile) {
          font-size: 18px;
          line-height: 24px;
          margin-top: 8px;
        }
      }
      .work-content-item-description {
        margin-top: 16px;
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        max-width: 705px;
        /* or 150% */

        letter-spacing: -0.03em;

        color: #6a6a6a;
        @media screen and (max-width: $on-mobile) {
          font-size: 16px;
          line-height: 24px;
          margin-top: 8px;
        }
      }
      .work-content-item-video {
        position: relative;
        line-height: 0;
        border: 1px solid rgba(0, 0, 0, 0.05);
        border-radius: 2px;

        img {
          max-width: 100%;
        }

        video {
          position: absolute;
          right: 26.85%;
          bottom: 3%;
          width: 13.4%;
          filter: drop-shadow(0px 6px 45px rgba(0, 0, 0, 0.1));
        }
      }
      .work-content-item-border {
        height: 1px;
        width: 100%;
        background: linear-gradient(
          90deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(255, 255, 255, 0) 0%,
          rgba(200, 200, 200, 0.67) 50%,
          rgba(0, 0, 0, 0) 100%
        );
      }
      .work-content-item-border-top {
        margin-top: 64px;
        height: 1px;
        width: 100%;
        background: linear-gradient(
          90deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(255, 255, 255, 0) 0%,
          rgba(200, 200, 200, 0.67) 50%,
          rgba(0, 0, 0, 0) 100%
        );
      }
    }
  }
}

.logos {
  margin: 280px 0;
  @media screen and (max-width: $on-mobile) {
    margin: 64px 0;
  }
  .logo-ticker {
    overflow: hidden;
    ul {
      li {
        img {
          width: 150px;
          height: 150px;
        }
        @media screen and (max-width: $on-mobile) {
          img {
            height: 100px;
            width: 100px;
          }
        }
      }
    }
  }
  .logos-title {
    color: #6a6a6a;
    text-align: center;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    /* identical to box height, or 167% */
    letter-spacing: -0.03em;
    @media screen and (max-width: $on-mobile) {
      font-size: 16px;
    }
  }
  .logos-outer {
    margin: 24px 0;
    flex-direction: row;
    align-items: center;
    display: flex;
    @media screen and (max-width: $on-mobile) {
      margin: 8px 0;
    }
  }
}

.bento {
  margin: 360px 0;
  @media screen and (max-width: $on-mobile) {
    margin: 80px 0;
  }
  .bento-title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 56px;
    /* identical to box height, or 140% */
    letter-spacing: -0.03em;
    color: #141414;
    @media screen and (max-width: $on-mobile) {
      font-size: 20px;
    }
  }
  .bento-grid {
    margin-top: 64px;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 48px;
    @media screen and (max-width: $on-desktop) {
      grid-gap: 24px;
    }
    @media screen and (max-width: $on-tablet) {
      grid-template-columns: repeat(4, 1fr);
      margin-top: 32px;
    }
  }

  .bento-grid-item {
    box-shadow: 0 2px 1px rgba(255, 255, 255, 1) inset,
      0 1px 0 rgba(0, 0, 0, 0.2);
    background: radial-gradient(
      67.41% 85.2% at 67.95% 25%,
      #ffffff 0%,
      #f7f7f7 100%
    );
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    padding: 48px;
    position: relative;
    @media screen and (max-width: $on-mobile) {
      padding: 24px;
    }
    .title {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 30px;
      line-height: 40px;

      letter-spacing: -0.03em;

      color: #141414;
      @media screen and (max-width: $on-mobile) {
        font-size: 18px;
        line-height: 24px;
      }
    }
    .description {
      max-width: 393px;
      margin-top: 16px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;

      letter-spacing: -0.03em;

      color: #6a6a6a;
      @media screen and (max-width: $on-mobile) {
        font-size: 16px;
        line-height: 24px;
        margin-top: 8px;
      }
    }
    img {
      max-width: 100%;
    }

    &:nth-child(1) {
      grid-column: span 5;
      grid-row: span 15;
      position: relative;
      overflow: hidden;
      @media screen and (max-width: $on-tablet) {
        grid-column: span 12;
        grid-row: span 23;
      }
      .bgone {
        position: absolute;
        width: 584px;
        height: 584px;
        background: linear-gradient(
          1.38deg,
          rgba(217, 217, 217, 0.3) 40.17%,
          rgba(217, 217, 217, 0) 98.83%
        );
        border-radius: 50%;
        top: 320px;
        left: 50%;
        margin-left: -292px;
        animation: bgone 5500ms ease-in-out infinite alternate;
        @media screen and (max-width: $on-tablet) {
          top: 120px;
        }
      }
      @keyframes bgone {
        0% {
          transform: scale(1, 1);
        }
        100% {
          transform: scale(0.9, 0.9);
        }
      }
      .bgtwo {
        position: absolute;
        background: linear-gradient(
          1.38deg,
          rgba(217, 217, 217, 0.5) 40.17%,
          rgba(217, 217, 217, 0) 98.83%
        );
        width: 478px;
        height: 478px;
        border-radius: 50%;
        top: 380px;
        left: 50%;
        margin-left: -239px;
        animation: bgtwo 5000ms ease-in-out infinite alternate;
        @media screen and (max-width: $on-tablet) {
          top: 160px;
        }
      }
      @keyframes bgtwo {
        0% {
          transform: scale(1, 1);
        }
        100% {
          transform: scale(0.95, 0.95);
        }
      }
      .simpledesign {
        position: absolute;
        bottom: 0;
        left: 50%;
        margin-left: -149px;
        @media screen and (max-width: $on-mobile) {
          margin-left: -122px;
        }
        .menu-outer {
          width: 298px;
          background: #ffffff;
          border-top: 1px solid rgba(0, 0, 0, 0.1);
          border-right: 1px solid rgba(0, 0, 0, 0.1);
          border-left: 1px solid rgba(0, 0, 0, 0.1);
          box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.15);
          border-radius: 20px 20px 0px 0px;
          @media screen and (max-width: $on-mobile) {
            width: 244px;
          }
        }
        .menu-label {
          text-transform: uppercase;
          font-family: "Roboto";
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 14px;
          letter-spacing: 0.1em;
          padding: 40px 0 0 32px;

          color: #929292;
          @media screen and (max-width: $on-mobile) {
            font-size: 11px;
            padding-left: 24px;
          }
        }
        .menu-spacer {
          background: #e3e3e3;
          height: 1px;
          margin: 16px 0;
          @media screen and (max-width: $on-mobile) {
            margin: 12px 0;
          }
        }
        .menu {
          list-style: none;
          padding: 24px 0 0 0;
          margin: 0;
          li {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 21px;
            /* identical to box height */

            color: #6a6a6a;
            padding: 16px 32px;
            cursor: pointer;
            @media screen and (max-width: $on-mobile) {
              font-size: 16px;
              padding: 12px 24px;
            }
            &:hover {
              color: #141414;
              transition: all 0.4s ease;
            }
          }
          &.second {
            padding: 0;
          }
        }
      }
    }

    &:nth-child(2) {
      grid-column: span 7;
      grid-row: span 15;
      overflow: hidden;
      @media screen and (max-width: $on-tablet) {
        grid-column: span 12;
        grid-row: span 20;
      }
      @media screen and (max-width: $on-mobile) {
        grid-row: span 18;
      }
      .video {
        display: grid;
        place-items: center;
        position: relative;
        left: auto;
        margin-left: auto;
        video {
          border-radius: 20px;
          max-width: 100%;
          margin-top: 64px;
          box-shadow: 0px 20px 24px rgba(0, 0, 0, 0.1);
          @media screen and (max-width: $on-tablet) {
            margin-top: 40px;
          }
        }
        .shadow {
          border: 1px solid rgba(0, 0, 0, 0.1);
        }
        .madefor {
          font-size: 12px;
          text-align: center;
          margin-top: 24px;
          color: #929292;
          @media screen and (max-width: $on-tablet) {
            margin-top: 8px;
          }
        }
      }
      .bgone {
        position: absolute;
        width: 584px;
        height: 584px;
        background: linear-gradient(
          1.38deg,
          rgba(217, 217, 217, 0.5) 40.17%,
          rgba(217, 217, 217, 0) 98.83%
        );
        border-radius: 50%;
        top: 0px;
        left: 75%;
        margin-left: -292px;
        animation: bgone 5500ms ease-in-out infinite alternate;
        @media screen and (max-width: $on-tablet) {
          right: -10%;
          left: auto;
          top: 64px;
          width: 292px;
          height: 292px;
        }
      }
      @keyframes bgone {
        0% {
          transform: scale(1, 1);
        }
        100% {
          transform: scale(0.6, 0.6);
        }
      }
    }
    &:nth-child(3) {
      grid-column: span 7;
      grid-row: span 16;
      overflow: hidden;

      @media screen and (max-width: $on-tablet) {
        grid-column: span 12;
      }

      .description {
        max-width: 522px;
      }

      .code {
        position: relative;
        margin-top: 64px;
        @media screen and (max-width: $on-tablet) {
          margin-top: 40px;
        }
        ul {
          list-style: none;
          padding: 24px;
          background: #fff;
          border-radius: 20px;
          box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.15);
          @media screen and (max-width: $on-mobile) {
            padding: 12px;
            border-radius: 12px;
          }

          li {
            font-family: "Roboto Mono";
            font-size: 18px;
            padding-top: 12px;
            margin-left: 24px;
            color: #212121;
            @media screen and (max-width: $on-mobile) {
              font-size: 12px;
              padding-top: 4px;
            }
            &:first-child,
            &:last-child {
              margin-left: 0;
            }
            &:first-child {
              padding-top: 0;
            }
            &:nth-child(n + 3):nth-child(-n + 8) {
              margin-left: 48px;
            }
            span {
              color: #6a6a6a;
            }
          }
        }

        .bgone {
          position: absolute;
          width: 584px;
          height: 584px;
          background: linear-gradient(
            1.38deg,
            rgba(217, 217, 217, 0.2) 40.17%,
            rgba(217, 217, 217, 0) 98.83%
          );
          border-radius: 50%;
          top: 120px;
          right: -40%;
          margin-left: -292px;
          animation: bgone 5500ms ease-in-out infinite alternate;
          @media screen and (max-width: $on-tablet) {
            left: 100%;
            margin-left: -110px;
            width: 220px;
            height: 220px;
          }
        }
        @keyframes bgone {
          0% {
            transform: scale(1, 1);
          }
          100% {
            transform: scale(0.9, 0.9);
          }
        }
        .bgtwo {
          position: absolute;
          background: linear-gradient(
            1.38deg,
            rgba(217, 217, 217, 0.3) 40.17%,
            rgba(217, 217, 217, 0) 98.83%
          );
          width: 478px;
          height: 478px;
          border-radius: 50%;
          top: 120px;
          right: -20%;
          margin-left: -239px;
          animation: bgtwo 5000ms ease-in-out infinite alternate;
          display: block;
          @media screen and (max-width: $on-tablet) {
            display: none;
          }
        }
        @keyframes bgtwo {
          0% {
            transform: scale(1, 1);
          }
          100% {
            transform: scale(0.95, 0.95);
          }
        }
      }
    }

    &:nth-child(4) {
      grid-column: span 5;
      grid-row: span 8;
      display: grid;
      place-items: center;
      @media screen and (max-width: $on-tablet) {
        grid-column: span 12;
      }
      .center-content {
        display: grid;
        place-items: center;
        .years {
          margin-bottom: 10px;
          @media screen and (max-width: $on-mobile) {
            margin-bottom: -4px;
          }
          span:first-child {
            font-family: "Inter";
            font-style: normal;
            font-weight: 600;
            font-size: 64px;
            line-height: 40px;
            /* identical to box height, or 62% */

            text-align: center;
            letter-spacing: -0.03em;

            color: #141414;
            @media screen and (max-width: $on-mobile) {
              font-size: 40px;
            }
          }
          span:last-child {
            font-family: "Inter";
            font-style: normal;
            font-weight: 500;
            font-size: 40px;
            line-height: 40px;
            text-align: center;
            letter-spacing: -0.03em;
            color: #141414;
            @media screen and (max-width: $on-mobile) {
              font-size: 24px;
              line-height: 30px;
            }
          }
        }
        .experience {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 24px;
          line-height: 32px;
          text-align: center;
          color: #6a6a6a;
          margin: 0;
          padding: 0;
          @media screen and (max-width: $on-mobile) {
            font-size: 16px;
          }
        }
      }
    }

    &:nth-child(5) {
      grid-column: span 5;
      grid-row: span 8;
      display: grid;
      place-items: center;
      @media screen and (max-width: $on-tablet) {
        grid-column: span 12;
        grid-row: span 9;
      }
      .work-contact {
        text-align: center;
        picture {
          img {
            width: 80px;
            height: 80px;
            border-radius: 50%;
            margin-bottom: 12px;
            filter: drop-shadow(0px 12px 28px rgba(0, 0, 0, 0.1));
            @media screen and (max-width: $on-mobile) {
              width: 64px;
              height: 64px;
              margin-bottom: 0px;
            }
          }
        }
        .open-to-work {
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          /* identical to box height, or 125% */
          margin-top: 8px;
          letter-spacing: -0.025em;
          color: #141414;
          @media screen and (max-width: $on-mobile) {
            font-size: 16px;
          }
          span {
            font-weight: 600;
          }
        }
        .contact-me {
          margin-top: 8px;
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          /* identical to box height, or 133% */
          color: #6a6a6a;
          text-align: center;
          @media screen and (max-width: $on-mobile) {
            font-size: 14px;
            margin-top: 4px;
          }
        }
      }
    }
  }
}

.sideprojects {
  margin: 220px 0;
  @media screen and (max-width: $on-mobile) {
    margin: 80px 0;
  }
  .sideprojects-title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 56px;
    /* identical to box height, or 140% */

    letter-spacing: -0.03em;

    color: #141414;
    @media screen and (max-width: $on-mobile) {
      font-size: 20px;
      line-height: 40px;
    }
  }
  .sideprojects-grid {
    margin-top: 64px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 48px;
    @media screen and (max-width: $on-desktop) {
      grid-gap: 24px;
    }
    @media screen and (max-width: $on-tablet) {
      grid-template-columns: 1fr;
      margin-top: 32px;
    }
    .sideprojects-grid-item {
      text-decoration: none;
      position: relative;
      box-shadow: 0 2px 1px rgba(255, 255, 255, 1) inset,
        0 1px 0 rgba(0, 0, 0, 0.2);
      background: #fff
        radial-gradient(67.41% 85.2% at 67.95% 25%, #ffffff 0%, #f7f7f7 100%);
      border: 1px solid rgba(0, 0, 0, 0.05);
      border-radius: 4px;
      padding: 48px;
      @media screen and (max-width: $on-mobile) {
        padding: 24px;
      }
      cursor: pointer;
      &:hover {
        border: 1px solid rgba(0, 0, 0, 0.1);
        .arrow {
          opacity: 1;
          transform: scale(1);
        }
      }
      .arrow {
        transition: all 0.2s ease;
        transform: scale(0.8);
        transform-origin: bottom left;
        position: absolute;
        top: 24px;
        right: 24px;
        opacity: 0;
      }
      .icon {
        display: grid;
        place-items: center;
        width: 64px;
        height: 64px;
        background: #ffffff;
        box-shadow: 0px 21px 26px -12px rgba(0, 0, 0, 0.15);
        border-radius: 6px;
        margin-bottom: 24px;
        @media screen and (max-width: $on-mobile) {
          width: 48px;
          height: 48px;
          svg {
            width: 24px;
            height: 24px;
          }
        }
      }
      .label {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        /* identical to box height, or 150% */

        letter-spacing: -0.03em;

        color: #686868;
        @media screen and (max-width: $on-mobile) {
          font-size: 12px;
        }
      }
      .title {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 30px;
        /* identical to box height, or 125% */

        letter-spacing: -0.025em;

        color: #141414;
        margin-top: 16px;
        text-decoration: none;
        display: block;
        @media screen and (max-width: $on-mobile) {
          font-size: 18px;
          line-height: 24px;
          margin-top: 8px;
        }
      }
      .description {
        margin-top: 16px;
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 30px;
        /* or 167% */

        letter-spacing: -0.03em;

        color: #6a6a6a;
        @media screen and (max-width: $on-mobile) {
          font-size: 16px;
          line-height: 24px;
          margin-top: 8px;
        }
      }
    }
  }
}

.businesses {
  margin: 220px 0;
  @media screen and (max-width: $on-mobile) {
    margin: 80px 0;
  }
  .businesses-title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 56px;
    /* identical to box height, or 140% */
    letter-spacing: -0.03em;
    color: #141414;
    @media screen and (max-width: $on-mobile) {
      font-size: 20px;
      line-height: 40px;
    }
  }
  .businesses-grid {
    margin-top: 64px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 48px;
    @media screen and (max-width: $on-desktop) {
      grid-gap: 24px;
    }
    @media screen and (max-width: $on-tablet) {
      grid-template-columns: 1fr;
      margin-top: 32px;
    }
    .businesses-grid-item {
      text-decoration: none;
      position: relative;
      box-shadow: 0 2px 1px rgba(255, 255, 255, 1) inset,
        0 1px 0 rgba(0, 0, 0, 0.2);
      background: #fff
        radial-gradient(67.41% 85.2% at 67.95% 25%, #ffffff 0%, #f7f7f7 100%);
      border: 1px solid rgba(0, 0, 0, 0.05);
      border-radius: 4px;
      padding: 48px;
      cursor: pointer;
      @media screen and (max-width: $on-mobile) {
        padding: 24px;
      }
      &:hover {
        border: 1px solid rgba(0, 0, 0, 0.1);
        .arrow {
          opacity: 1;
          transform: scale(1);
        }
      }
      .arrow {
        transition: all 0.2s ease;
        transform: scale(0.8);
        transform-origin: bottom left;
        position: absolute;
        top: 24px;
        right: 24px;
        opacity: 0;
      }
      .icon {
        display: grid;
        place-items: center;
        width: 80px;
        height: 80px;
        background: #ffffff;
        box-shadow: 0px 21px 26px -12px rgba(0, 0, 0, 0.15);
        border-radius: 6px;
        margin-bottom: 24px;
        @media screen and (max-width: $on-mobile) {
          width: 48px;
          height: 48px;
          svg {
            width: 36px;
            height: 36px;
          }
        }
      }
      .label {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        /* identical to box height, or 150% */

        letter-spacing: -0.03em;

        color: #686868;
        @media screen and (max-width: $on-mobile) {
          font-size: 12px;
        }
      }
      .title {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 30px;
        line-height: 40px;
        /* identical to box height, or 125% */

        letter-spacing: -0.025em;

        color: #141414;
        margin-top: 16px;
        text-decoration: none;
        display: block;
        @media screen and (max-width: $on-mobile) {
          font-size: 18px;
          margin-top: 8px;
          line-height: 24px;
        }
      }
      .description {
        margin-top: 16px;
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        /* or 167% */

        letter-spacing: -0.03em;

        color: #6a6a6a;
        @media screen and (max-width: $on-mobile) {
          font-size: 16px;
          line-height: 24px;
          margin-top: 8px;
        }
      }
      &:nth-child(2) {
        .icon {
          @media screen and (max-width: $on-mobile) {
            svg {
              width: 24px;
              height: 24px;
            }
          }
        }
      }
    }
  }
}

footer {
  margin: 220px 0;
  @media screen and (max-width: $on-mobile) {
    margin: 80px 0 120px 0;
  }
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: $on-mobile) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .footer-right {
    @media screen and (max-width: $on-mobile) {
      margin-top: 32px;
    }
    a {
      margin-right: 32px;
      svg {
        fill: #141414;
        transition: all 0.2s ease;
      }
      &:hover {
        svg {
          fill: #929292;
        }
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
